import React, { useEffect, useState } from "react";
import "./Project.css";
import { useSearchParams } from "react-router-dom";
import { AiOutlineFilePdf } from "react-icons/ai";

const ProjectFull = () => {
  const [searchParam] = useSearchParams();
  const [posts, setPosts] = useState([]);
  const [items, setItems] = useState([]);
  const [filterLetter, setFilterLetter] = useState('');
  const lang = localStorage.getItem("lang") || "uz";

  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/project/`)
      .then((response) => response.json())
      .then((data) => {
        const name = searchParam.get("name");
        setPosts(data.filter(user => user.name === name));
        setItems(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang, searchParam]);

  // alfabet harflari bilan tayyorlash

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    console.log("Modal ochilmoqda"); // Modal ochilganini konsolga chiqaramiz
    setIsOpen(true);
};

const closeModal = () => {
    setIsOpen(false);
};
  
  return (
    <>
      <div style={{ height: "90px" }}></div>
      <div className="container">
        {posts.map((item) => {
          // Har bir item uchun filtrlangan sources_info ni olish
          const filteredSources = filterLetter
            ? item.sources_info?.filter(subItem => 
                subItem && 
                typeof subItem.name === 'string' && 
                subItem.name.toUpperCase().startsWith(filterLetter)
              ) || []
            : item.sources_info || [];
            return(
           
          <div key={item.id}>
            <div style={{ border: "1px solid darkgrey", marginTop: "20px" }}></div>
            <div className="row" style={{ margin: "20px" }}>
              <div className="col-md-4">
                <img src={item.icon} style={{ maxHeight: "300px", maxWidth: "300px" }} />
              </div>
              <div className="col-md-8">
                <h1 style={{textAlign: "left"}}>{item.name}</h1>
                <p><strong>Loyiha Rahbari:</strong> {item.leader}</p>
                <p><strong>Loyiha Shifri:</strong> {item.project_number}</p>
                <p><strong>Loyiha Muddati:</strong> {item.date}</p>
              </div>
            </div>
            <div style={{ border: "1px solid darkgrey", marginTop: "20px" }}></div>
            <div className="text-k" dangerouslySetInnerHTML={{ __html: item.full }} />
            <ul>
              {item.sources_pdf && item.sources_pdf.map((pdf) => (
                <li key={pdf.id} style={{ border: "1px solid darkgrey", padding: "5px" }}>
                  <a href={pdf.pdf}>{pdf.name} <b style={{ color: "blue" }}><AiOutlineFilePdf /></b></a>
                </li>
              ))}
            </ul>
            <div style={{ marginTop: "20px" }}>
              <div>
                {alphabet.split('').map((letter) => (
                  <button key={letter} onClick={() => setFilterLetter(letter)} style={{ background: "#94f2a5" }}>
                    {letter}
                  </button>
                ))}
                <button onClick={() => setFilterLetter('')}>Barchasini ko'rsatish</button>
              </div>
              <div style={{ margin: "20px", border: "1px solid #ccc", padding: "10px" }}>
              {filteredSources.length > 0 ? (
                    <ul>
                      {filteredSources.map((subItem, index) => (
                        <li key={index} className="row" style={{ margin: "20px" }}>
                          <div className="col-4">{subItem.name}</div>
                          <div className="col-8" style={{ borderLeft: "1px solid darkgrey", borderBottom: "1px solid darkgrey"}}>
                          <div>
            <img 
                src={subItem.photo} 
                alt="Misol rasm" 
                className="zoomable-image" 
                width={800} 
                height={400} 
                onClick={openModal} // Rasmga bosilganda modal ochiladi
            />
            {isOpen && (
                <div className="modal" onClick={closeModal}> {/* Modalni yopish uchun bosish */}
                    <img 
                        src={subItem.photo} 
                        alt="Katta rasm" 
                        className="modal-image" 
                    />
                </div>
            )}
        </div>
                          </div>
                        </li>
                      ))}
                    </ul> 
                  ) : (
                    <p>Hech qanday natija topilmadi.</p>
                  )}
              </div>
            </div>
            <div style={{ border: "1px solid darkgrey", marginBottom: "20px" }}></div>
          </div>
        )})}
      </div>
    </>
  );
};

export default ProjectFull;
